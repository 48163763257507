import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <div>
    <Layout>
      <div className="aga-container container-404 aga-fade-in">
        <h1>NOT FOUND</h1>
        <p className="center">This page does not exist.</p>
        <p className="center">Navigate back to the <Link to="/">Better IBD Care Homepage</Link> to explore the site.</p>
      </div>
    </Layout>
  </div>
  
)

export default NotFoundPage
